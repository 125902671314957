
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'm3-theme' as theme;
@use 'sass:map';
@use 'sass:list';
// Plus imports for other components in your app.
@use 'ngx-toastr/toastr';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the theme object.
$cloudigo-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: theme.$primary,
    // tertiary: mat.$blue-palette,
  ),
  typography: (
    plain-family: 'Figtree',
    brand-family: 'Figtree',
    bold-weight: 800,
    medium-weight: 600,
    regular-weight: 400
  ),
  density: (
    scale: -1,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($cloudigo-theme);
}

:root {
  --bar-height: 54px;
  --mdc-dialog-container-shape: 4px;
  --mdc-fab-container-shape: 100px;
  --mdc-fab-small-container-shape: 100px;
  --mat-divider-color: #8491af50;
  --mat-expansion-container-background-color: #fdfeff;

  .mat-mdc-button .mat-mdc-button-touch-target{
    height: unset;
  }

  .mat-accordion .mat-expansion-panel{
    transition-timing-function: linear !important;
  }

  --swiper-theme-color: #0075ff;

}

@media (max-width: 900px) {
  .mat-bottom-sheet-container > *{
    display: block;
    margin-bottom: var(--bar-height);
  }
}

cloudigo-root:has(.translatable) .language{
  display: flex;
}

.mat-mdc-dialog-surface{
  overflow-x: hidden;
}

a:not(.mdc-button){
  color: #0075ff;
  &:hover, &:focus{
      opacity: 0.8;
  }
}

.bullets-container {
  margin-bottom: 5px;
}

.btn-container>button:where(.prev,.next){
  background: var(--bullet-current-color, var(--plan-background-color)) !important;
}

.bullet.current {
  background: var(--bullet-current-color, var(--plan-background-color)) !important;
}

small.smaller{
  font-size: 11px;
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($cloudigo-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($cloudigo-theme);
/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { 
  margin: 0; 
  font-family: "Figtree", "Helvetica Neue", sans-serif; 
  color: #181921;
  
}

button {
  font-family: inherit;
}

input {
  font-family: inherit;
  font-size: 16px;
}

.font-regular, .font-normal {
  font-weight: 400;
}

.font-semibold{
  font-weight: 600;
}

.font-light{
  font-weight: 300;
}

.font-bold{
  font-weight: 800;
}

.font-extrabold {
  font-weight: 900;
  letter-spacing: 0.5px;
}

.cosmetic{
  pointer-events: none;
  user-select: none;
  cursor: none;
}

.interactive{
  cursor: pointer;
}

.mat-icon.sized, .mdc-button>.mat-icon.sized{
  width: var(--icon-size, 24px);
  height: var(--icon-size, 24px);
  font-size: var(--icon-size, 24px);
}

.mat-icon.sized-imp, .mdc-button>.mat-icon.sized-imp{
  width: var(--icon-size, 24px) !important;
  height: var(--icon-size, 24px) !important;
  font-size: var(--icon-size, 24px) !important;
}

// PADDING AND MARGIN AUTO CREATORS - KEEP LAST
$mpselectors: "margin" "padding";
$shortmp: m p;
$sizes: 30 28 24 20 16 15 12 10 8 6 5 4 3 2 1 0 -2 -4;
@each $size in $sizes{
    @each $mpsel in $mpselectors{

        $i: list.index($mpselectors, $mpsel);
        $short: list.nth($shortmp, $i);
        
        .#{$short}-#{$size}{
            #{$mpsel}: #{$size}px;
        }

        .#{$short}x-#{$size}{
            #{$mpsel}-left: #{$size}px;
            #{$mpsel}-right: #{$size}px;
        }
    
        .#{$short}y-#{$size}{
            #{$mpsel}-top: #{$size}px;
            #{$mpsel}-bottom: #{$size}px;
        }
    
        .#{$short}l-#{$size}{
            #{$mpsel}-left: #{$size}px;
        }
    
        .#{$short}r-#{$size}{
            #{$mpsel}-right: #{$size}px;
        }
    
        .#{$short}t-#{$size}{
            #{$mpsel}-top: #{$size}px;
        }
    
        .#{$short}b-#{$size}{
            #{$mpsel}-bottom: #{$size}px;
        }  
        
        .#{$short}-#{$size}i{
            #{$mpsel}: #{$size}px !important;
        }

        .#{$short}x-#{$size}i{
            #{$mpsel}-left: #{$size}px !important;
            #{$mpsel}-right: #{$size}px !important;
        }
    
        .#{$short}y-#{$size}i{
            #{$mpsel}-top: #{$size}px !important;
            #{$mpsel}-bottom: #{$size}px !important;
        }
    
        .#{$short}l-#{$size}i{
            #{$mpsel}-left: #{$size}px !important;
        }
    
        .#{$short}r-#{$size}i{
            #{$mpsel}-right: #{$size}px !important;
        }
    
        .#{$short}t-#{$size}i{
            #{$mpsel}-top: #{$size}px !important;
        }
    
        .#{$short}b-#{$size}i{
            #{$mpsel}-bottom: #{$size}px !important;
        }  
    }    
}

.m-auto{
    margin: auto;
}

.mx-auto{
    margin-left: auto;
    margin-right: auto;
}

.my-auto{
    margin-top: auto;
    margin-right: auto;
}

.ml-auto{
    margin-left: auto;
}

.mr-auto{
    margin-right: auto;
}

.mt-auto{
    margin-top: auto;
}

.mb-auto{
    margin-bottom: auto;
}

.p-inset{
  box-sizing: border-box;
}

// POSITION AUTO CREATORS - KEEP LAST
$sideselectors: "top" "right" "left" "bottom";
$shortside: t r l b;
@each $position in $sizes{
    @each $side in $sideselectors{
        $i: list.index($sideselectors, $side);
        $short: list.nth($shortside, $i);
        
        .pos#{$short}-#{$position}{
            #{$side}: #{$position}px;
        }
    }    
}

// GAP AUTO CREATORS - KEEP LAST
$directionselectors: "column" "row";
$shortdirection: c r;
@each $size in $sizes{
    @each $direction in $directionselectors{
        $i: list.index($directionselectors, $direction);
        $short: list.nth($shortdirection, $i);
        
        .#{$short}gap-#{$size}{
            #{$direction}-gap: #{$size}px;
        }
    }    
}

$minWidths: 40 50 75 100 125 150 200 300 320 400 500;
@each $width in $minWidths{
    .min-width-#{$width}{
        min-width: #{$width}px;
    }

    .min-width-#{$width}i{
        min-width: #{$width}px !important;
    }
}

.block{
  display: block;
}

.inline-block{
  display: inline-block;
}

.f-col{
  display: flex;
  flex-direction: column;
}

.f-row{
  display: flex;
  flex-direction: row;
}

.f-main-start{
  display: flex;
  justify-content: flex-start;
}

.f-main-end{
  display: flex;
  justify-content: flex-end;
}

.f-main-center{
  display: flex;
  justify-content: center;
}

.f-main-between{
  display: flex;
  justify-content: space-between;
}

.f-main-around{
  display: flex;
  justify-content: space-around;
}

.f-main-evenly{
  display: flex;
  justify-content: space-evenly;
}

.f-cross-start{
  display: flex;
  align-items: flex-start;
}

.f-cross-end{
  display: flex;
  align-items: flex-end;
}

.f-cross-center{
  display: flex;
  align-items: center;
}

.f-col{
  display: flex;
  flex-direction: column;
}

.f-wrap{
  display: flex;
  flex-wrap: wrap;
}

.flex{
  display: flex;
}

.inline-flex{
  display: inline-flex;
}

.round{
  border-radius: 100%;
}

.rounded{
  border-radius: 1000px;
}

.no-decoration {
  text-decoration: none;
}

.brand-map-marker{
  width: 45;
  height: 45px;
  display: block;
  border-radius: 100%;
  border: 2px solid #181921;
  box-shadow: 0 0 8px rgba(0,0,0,0.3);
}

google-map{
  display: block;
  width: 100%;
  height: 100%;
  >.map-container{
      width: 100% !important;
      height: 100% !important;
  }
}

.gm-style-iw-ch{
  padding-top: 10px;
}

.toast-container .ngx-toastr {
  //color
  box-shadow: 0 0 6px rgba(0,0,0,0.3);
  transition: all 0.2s linear;
  &:hover{
    box-shadow: 0 0 8px rgba(0,0,0,0.5);
  }
  &.toast-info{
    background-color: #0075ff;
  }
  &.toast-warning{
    background-color: #ffca00;
  }
  &.toast-success{
    background-color: #47ab1b;
  }
  &.toast-error{
    background-color: #ff4735;
  }
}

.w-full{
  width: 100%;
}

.h-full{
  height: 100%;
}

.svg-loader{
  object{
      max-width: 300px;
      min-width: 300px;
  }
}

.gradient-horizontal, .gradient-vertical, .basic-horizontal, .basic-vertical, .premium-horizontal, .premium-vertical, .accent-horizontal, .accent-vertical, .dark-horizontal, .dark-vertical {

  &:not([disabled]){
    --mdc-filled-button-container-color: white;
    --mdc-protected-button-label-text-color: white;
    --mdc-text-button-label-text-color: white;
    --mdc-outlined-button-label-text-color: white;
    --mdc-icon-button-label-text-color: white;
    background-color: white;
  }
}

.gradient-vertical:not([disabled]){
  background-image: var(--plan-gradient-vertical) !important;
}

.gradient-horizontal:not([disabled]){
  background-image: var(--plan-gradient-horizontal) !important;
}

.basic-vertical:not([disabled]){
  background-image: linear-gradient(180deg, #3AB8FFcc 0%, #0075ffcc 100%) !important;
}

.basic-horizontal:not([disabled]){
  background-image: linear-gradient(90deg, #3AB8FFcc 0%, #0075ffcc 100%) !important;
}

.premium-vertical:not([disabled]){
  background-image: linear-gradient(180deg,#ffca00cc -100%,#ff4735cc 80%,#ff3728cc 150%)!important;
}

.premium-horizontal:not([disabled]){
  background-image: linear-gradient(90deg,#ffca00cc -100%,#ff4735cc 80%,#ff3728cc 150%) !important;
}

.accent-vertical:not([disabled]){
  background-image: linear-gradient(180deg,#FBD434 0%,#ECBD01 100%)!important;
}

.accent-horizontal:not([disabled]){
  background-image: linear-gradient(90deg,#FBD434 0%,#ECBD01 100%) !important;
}

.dark-vertical:not([disabled]){
  background-image: linear-gradient(180deg,#8491AF -50%,#181921 60%)!important;
}

.dark-horizontal:not([disabled]){
  background-image: linear-gradient(90deg,#8491AF -50%,#181921 100%) !important;
}

.painted{
  &.mat-mdc-icon-button{
    --mat-icon-color: var(--custom-color, #0075ff);
    --mdc-icon-button-icon-color: var(--custom-color, #0075ff);
    --mat-icon-button-ripple-color: rgb(from var(--custom-color, #0075ff) r g b  / 0.12);
  }
  &.mat-mdc-button{
    --mdc-text-button-label-text-color: var(--custom-color, #0075ff);
    --mat-text-button-state-layer-color: var(--custom-color, #0075ff);
    --mat-text-button-ripple-color: rgb(from var(--custom-color, #0075ff) r g b  / 0.12);
  }
  
  &.mat-mdc-unelevated-button{
    --mdc-filled-button-container-color: var(--custom-color, #0075ff);
    --mdc-filled-button-label-text-color: var(--custom-color-secondary, #ffffff);
  }
  
  &.mat-mdc-raised-button{
    --mdc-protected-button-label-text-color: var(--custom-color, #0075ff);
    --mat-protected-button-state-layer-color: var(--custom-color, #0075ff);
    --mat-protected-button-ripple-color: rgb(from var(--custom-color, #0075ff) r g b  / 0.12);
  }
  
  &.mat-mdc-outlined-button{
    --mdc-outlined-button-label-text-color: var(--custom-color, #0075ff);
    --mat-outlined-button-state-layer-color: var(--custom-color, #0075ff);
    --mat-outlined-button-ripple-color: rgb(from var(--custom-color, #0075ff) r g b  / 0.12);
  }

  &.mat-mdc-slide-toggle{
    --mdc-switch-selected-track-color: var(--custom-color, #0075ff);
    --mdc-switch-selected-focus-state-layer-color: var(--custom-color, #0075ff);
    --mdc-switch-selected-hover-state-layer-color: var(--custom-color, #0075ff);
    --mdc-switch-selected-pressed-state-layer-color: var(--custom-color, #0075ff);
    --mdc-switch-selected-focus-track-color: var(--custom-color, #0075ff);
    --mdc-switch-selected-hover-track-color: var(--custom-color, #0075ff);
    --mdc-switch-selected-pressed-track-color: var(--custom-color, #0075ff);
    --mdc-switch-selected-icon-color: var(--custom-color, #0075ff);
    --mdc-switch-selected-focus-handle-color: white;
    --mdc-switch-selected-hover-handle-color: white;
    --mdc-switch-selected-pressed-handle-color: white;
  }

  &.mat-mdc-list{
    --mdc-list-list-item-label-text-color: var(--custom-color, #0075ff);//main text
    --mdc-list-list-item-leading-icon-color: var(--custom-color, #0075ff);//prefix icon
    --mdc-list-list-item-supporting-text-color: var(--custom-color, #0075ff);//sub text
    --mdc-list-list-item-trailing-supporting-text-color: var(--custom-color, #0075ff);//small text
    --mdc-list-list-item-trailing-icon-color: var(--custom-color, #0075ff);//suffix icon
    --mdc-list-list-item-selected-trailing-icon-color: var(--custom-color, #0075ff);//selected 

    --mdc-list-list-item-hover-label-text-color: rgb(from var(--custom-color, #0075ff) r g b  / 0.8);
    --mdc-list-list-item-hover-leading-icon-color: rgb(from var(--custom-color, #0075ff) r g b  / 0.8);
  }

  &.mat-mdc-tab-group{
    --mat-tab-header-active-focus-indicator-color: var(--custom-color, #0075ff);
    --mat-tab-header-active-hover-indicator-color: var(--custom-color, #0075ff);
    --mat-tab-header-divider-color: rgb(from var(--custom-color, #0075ff) r g b  / 0.1);
    --mdc-tab-indicator-active-indicator-color: var(--custom-color, #0075ff);
    
  }

  &:where(carousel){
    --bullet-current-color: var(--custom-color, #0075ff);
  }

  &:where(p,span,h1,h2,h3,h4,h5,h6,input,.mat-icon,small,strong,b,i,a:not(.mat-mdc-button)){
    color: var(--custom-color);
  }
  &:where(div, section, nav, footer){
    background-color: var(--custom-color);
    color: var(--custom-color-secondary);
  }
}



.mat-mdc-slide-toggle>.mdc-form-field{
  width: 100%;
  justify-content: space-between;
  >.mdc-label{
    margin: 0;
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.uppercase{
  text-transform: uppercase;
}

.lowercase{
  text-transform: lowercase;
}

.capital{
  text-transform: capitalize;
}

.text-center{
  text-align: center;
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}

.text-justify{
  text-align: justify;
}

.mat-mdc-form-field-hint{
  color: #8491af;
  font-weight: 300;
}

.mat-mdc-form-field-error{
  color: #ad180d;
  font-weight: 300;
}

@keyframes rotation{
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes halfrotation {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(180deg) }
}

.rotating{
  transform-origin: 50% 50%;
  transform: rotate(0deg);
  animation: halfrotation 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s infinite normal forwards;
  // transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) cubic-bezier(0.68, -0.55, 0.265, 1.55)
}

.full-rotating{
  transform-origin: 50% 50%;
  animation: rotation 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s infinite normal forwards;
}

.no-footer ~ cloudigo-footer{
  display: none;
}

.initial-loader{
  display: none;
}

cloudigo-homev2[ngh]:not(.home){
  display: none;

  & ~ cloudigo-footer {
    opacity: 0 !important;
  }

  & + .initial-loader{
    display: flex !important;
  }
}


//? STYLES REQUIRED TO BE GLOBAL

.our-cards>div:hover .fancy-bg{
  transform-origin: 50% 50%;
  animation: rotation 2.5s linear 0s infinite normal forwards;
}

.ipv_dropdown {
  .ipv_selected-item, .ipv_search_box, .ipv_search_box.filtered {
    height: 50px;
    background-color: #ebf3ff;
    border: none;
    border-radius: 4px;
    &:focus{
      outline: #0075ff solid 1px;
    }
  }

  .ipv_country-name {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ipv_selected-item {
    .ipv_country-name{
      max-width: 100%;
      display: block;
    }
  }
}

.as-country .ipv_dropdown .ipv_selected-item .ipv_flag + div{
  max-width: calc(100% - 50px);
}

.custom-field .ipv_dropdown{
  .ipv_selected-item, .ipv_search_box, .ipv_search_box.filtered {
    background-color: white;
    height: 36px;
    border: 1px solid #8491af;
    &:hover{
      background-color: white;
    }
    &:focus{
      outline: none;
      border-color: var(--plan-background-color);
    }
  }
}

ul.ipv_country-list {
  z-index: 100;
  min-width: 250px;
}

.grecaptcha-badge{
  z-index: 100 !important;
  bottom: 70px !important;
}

cloudigo-root:not(:has(cloudigo-auth)) ~ div>.grecaptcha-badge{
  display: none !important;
}

.profile-tabber .mat-mdc-tab-header{
  display: none;
}

.app-overlay{
  pointer-events: none;
  position: fixed;
  z-index: 11;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.skeleton{
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

.skeleton-text{
  color: transparent;
  border-radius: 5px;
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

.christmas{
  .topBanner{
    background-color: #DD0621 !important;
  }
}

.rtl{
  direction: rtl;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
